// RADIO
/* Base for label styling */
[type='radio']:not(:checked),
[type='radio']:checked {
  position: absolute;
  left: -9999px;
}

[type='radio']:not(:checked) + label,
[type='radio']:checked + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}

[type='radio']:not(:checked) + label.right,
[type='radio']:checked + label.right {
  padding-left: 0;
  padding-right: 20px;
  cursor: pointer;
}

/* checkbox aspect */
[type='radio']:not(:checked) + label:before,
[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 20px;
  height: 20px;
  border: 1px solid #aaa;
  background: #f8f8f8;
  border-radius: 50%;
  user-select: none;
}

/* checked mark aspect */
[type='radio']:not(:checked) + label:after,
[type='radio']:checked + label:after {
  content: '✔';
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 20px;
  line-height: 0.8;
  color: $primary;
  transition: all 0.2s;
  user-select: none;
}

[type='radio']:not(:checked) + label.right:before,
[type='radio']:checked + label.right:before {
  left: auto;
  right: -12px;
  top: 3px;
}

/* checked mark aspect */
[type='radio']:not(:checked) + label.right:after,
[type='radio']:checked + label.right:after {
  left: auto;
  right: -11px;
  top: 4px;
}

/* checked mark aspect changes */
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}

[type='radio']:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

/* disabled checkbox */
[type='radio']:disabled:not(:checked) + label:before,
[type='radio']:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

[type='radio']:disabled:checked + label:after {
  //display: none;
  color: #999;
}

[type='radio']:disabled + label {
  //display: none;
  color: #aaa;
}

/* accessibility */
[type='radio']:checked:focus + label:before,
[type='radio']:not(:checked):focus + label:before {
  border: 1px dotted lighten($primary, 10%);
}

/* hover style just for information */
label:hover:before {
  border: 1px solid lighten($primary, 20%) !important;
}
