@charset "UTF-8";

@font-face {
  font-family: "degineo";
  src: url("../fonts/degineo/degineo.eot");
  src: url("../fonts/degineo/degineo.eot?#iefix") format("embedded-opentype"),
    url("../fonts/degineo/degineo.woff") format("woff"),
    url("../fonts/degineo/degineo.ttf") format("truetype"),
    url("../fonts/degineo/degineo.svg#degineo") format("svg");
  font-weight: normal;
  font-style: normal;

}


[class^="icon-"]:not(.ngxmdpicon, .mydrpicon),
[class*=" icon-"]:not(.ngxmdpicon, .mydrpicon) {
  font-family: "degineo" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[icon]:before {
  font-family: "degineo" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


[class^="icon-"]:before:not(.ngxmdpicon, .mydrpicon),
[class*=" icon-"]:before:not(.ngxmdpicon, .mydrpicon) {
  font-family: "degineo" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-3d-model:before {
  content: "\62";
}

.icon-3d-printer:before {
  content: "\63";
}

.icon-add-basket:before {
  content: "\64";
}

.icon-basket:before {
  content: "\65";
}

.icon-download:before {
  content: "\66";
}

.icon-eye:before {
  content: "\67";
}

.icon-eye-close:before {
  content: "\68";
}

.icon-menu:before {
  content: "\69";
}

.icon-3d-file:before {
  content: "\61";
}

.icon-culture:before {
  content: "\6a";
}

.icon-education:before {
  content: "\6b";
}

.icon-hospitality:before {
  content: "\6c";
}

.icon-house:before {
  content: "\6d";
}

.icon-industry:before {
  content: "\6e";
}

.icon-sport:before {
  content: "\6f";
}

.icon-scientist:before {
  content: "\70";
}

.icon-plus:before {
  content: "\71";
}

.icon-other:before {
  content: "\72";
}

.icon-office:before {
  content: "\73";
}

.icon-medical:before {
  content: "\74";
}

.icon-market:before {
  content: "\75";
}

.icon-book:before {
  content: "\76";
}

.icon-bank:before {
  content: "\77";
}

.icon-viewer-3d:before {
  content: "\78";
}

.icon-address:before {
  content: "\79";
}

.icon-of:before {
  content: "\7a";
}

.icon-arrow:before {
  content: "\41";
}

.icon-sign-out:before {
  content: "\45";
}

.icon-arrow-circle:before {
  content: "\42";
}

.icon-model-csv:before {
  content: "\43";
}

.icon-model-excel:before {
  content: "\44";
}

.icon-administrative:before {
  content: "\46";
}

.icon-collection-address:before {
  content: "\47";
}

.icon-dashboard:before {
  content: "\48";
}

.icon-orders:before {
  content: "\4a";
}

.icon-packaging:before {
  content: "\4b";
}

.icon-pricing:before {
  content: "\4d";
}

.icon-requests:before {
  content: "\4e";
}

.icon-user-accounts:before {
  content: "\4f";
}

.icon-portfolio:before {
  content: "\4c";
}

.icon-my-printers:before {
  content: "\49";
}

.icon-phone:before {
  content: "\51";
}

.icon-mail:before {
  content: "\50";
}

.icon-plus-square-o:before {
  content: "\52";
}

.icon-degineo-page:before {
  content: "\53";
}
