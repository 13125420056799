/* You can add global styles to this file, and also import other style files */
/*
html {
  height: 100%;
}

body {
  min-height: 100%;
}
*/

.login-bg {
  background: #06c;
  background: -webkit-linear-gradient(to right, #06c, #20cb75);
  background: linear-gradient(to right, #06c, #20cb75);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 10px;
}
/*
@import '../../../node_modules/bootstrap/scss/bootstrap';
$fa-font-path : '../../../node_modules/font-awesome/fonts';
@import '../../../node_modules/font-awesome/scss/font-awesome';
*/