.table-center {
  td,
  th {
    text-align: center;
  }
}

.table {
  th,
  td {
    vertical-align: middle;
  }
}
