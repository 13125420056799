@import 'colors';
@import 'bootstrap_customization_vars';

//$enable-responsive-font-sizes: true;
@import '../../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../../node_modules/bootstrap/scss/vendor/rfs';

$fa-font-path: '../../../../node_modules/@fortawesome/fontawesome-free/webfonts';
@import '../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../../../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss';

@import 'radio-style';
@import 'forms';
@import 'spin';
@import 'font-weights';
@import 'daterangepicker';
@import 'shadows';
@import 'containers';
@import 'degineo-font';
@import 'width-breakpoints';
@import 'headers';
@import 'bootstrap_customization';
@import 'tables';

* {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

html {
  height: 100%;
}

body {
  color: #314559;
  min-height: 100%;
}

label {
  padding-left: 0.5rem;
  font-weight: 500;
  margin-bottom: unset;
  line-height: 1;
}

label:focus {
  color: $primary;
}

a {
  color: $primary;
  text-decoration: none;
}

a:hover {
  color: $primary;
  //text-decoration: none;
}

.no-hover:hover {
  text-decoration: none;
}

.fs-1 {
  @include font-size(0.7rem);
}

.fs-2 {
  @include font-size(0.9rem);
}

.fs-3 {
  @include font-size(1rem);
}

.fs-4 {
  @include font-size(1.2rem);
}

.fs-5 {
  @include font-size(2rem);
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
  user-select: none;
}

.underline {
  text-decoration: underline;
}

.hover-underline:hover {
  text-decoration: underline;
}

.no-select {
  user-select: none;
}

.custom-tooltip {
  opacity: 1 !important;

  .arrow::before {
    border-bottom-color: #666;
  }

  .tooltip-inner {
    background-color: #666;
    font-family: Roboto, Arial, Helvetica, sans-serif;
  }
}

//L'autocomplete places de google maps (utilisé popup addresse tunnel de paiement)
.pac-container {
  z-index: 1051;

  &:after {
    content: none !important;
    background-image: none !important;
    height: 0px;
  }
}

.link-unstyled {
  &,
  &:visited,
  &:hover,
  &:active,
  &:focus,
  &:active:hover {
    font-style: inherit;
    color: inherit;
    background-color: transparent;
    font-size: inherit;
    text-decoration: none;
    font-variant: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-family: inherit;
    border-radius: inherit;
    border: inherit;
    outline: inherit;
    box-shadow: inherit;
    padding: inherit;
    vertical-align: inherit;
  }
}

.fakeinput {
  display: inline-block;
  width: 100%;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #314559;
  background-color: #fff;
  &.StripeElement--focus {
    border: 1px solid #1975d1;
    box-shadow: unset;
    color: #314559;
  }
}

.close-icon {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 2rem;
  color: $secondary;
  cursor: pointer;
  transition: 0.15s;
  z-index: 1300;
  &:hover {
    color: $medium;
  }
}

.input-icon {
  position: absolute;
  top: 12px;
  left: 12px+16px;
  color: $secondary;
}
.input-with-icon {
  padding-left: 2.5rem !important;
}
