$primary: #06c;
$success: #00c462;
$secondary: #a2abb4;
$dark: #314559;
$medium: #5a6a7a;
$light: #c1c7cd;
$light-30p: #eceef0;
$warning: #ffc107;
$danger: #d82929;
$info: #1975d1;
