@media (min-width: 768px) {
  .container-small {
    width: 500px;
  }
  .container-large {
    width: 970px;
  }
}
@media (min-width: 992px) {
  .container-small {
    width: 700px;
    padding-right: 4rem !important;
    padding-left: 4rem !important;
  }
  .container-large {
    width: 1170px;
  }
}
@media (min-width: 1200px) {
  .container-small {
    width: 700px;
  }
  .container-large {
    width: 1500px;
  }
}

.container-large {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-small {
  max-width: 100%;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-right: auto;
  margin-left: auto;
}
