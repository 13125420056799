.mydrp .btnclear,
.mydrp .btnpicker {
  display: inline-block !important;
  font-weight: 400 !important;
  text-align: center !important;
  vertical-align: middle !important;
  user-select: none !important;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  border-radius: 0.25rem !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  height: calc(2.25rem + 2px) !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  letter-spacing: 0.8px !important;
  color: #aaaaaa !important;
  border-color: #aaaaaa !important;
  position: relative;
  z-index: 2;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px !important;
  width: auto !important;
}


.mydrp .btnclear:hover,
.mydrp .btnpicker:hover {
  background-color: #aaaaaa !important;
  color: black !important;
}


.mydrp .btnclear {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}


.selbtngroup {
  display: flex !important;
  position: relative !important;
  vertical-align: middle !important;
  white-space: inherit !important;
  width: auto !important;
  font-size: inherit !important;
}


.icon-mydrpcalendar,
.icon-mydrpremove {
  -webkit-font-smoothing: antialiased !important;
  display: inline-block !important;
  font-style: normal !important;
  font-variant: normal !important;
  text-rendering: auto !important;
  line-height: 1 !important;
}

.mydrp input {
  box-sizing: border-box !important;
  margin: 0 !important;
  overflow: visible !important;

  display: block !important;
  width: 100% !important;
  height: calc(2.25rem + 2px) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;

  position: relative !important;
  flex: 1 1 auto !important;
  width: 1% !important;
  margin-bottom: 0 !important;

  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  font-family: inherit !important;
}

@media (min-width: 576px) {
  .mydrp input {
    display: inline-block !important;
    vertical-align: middle !important;
  }
}

.selectiongroup {
  position: relative !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: stretch !important;

  font-family: inherit !important;

  width: auto !important;
}

my-date-range-picker>div {
  border: none !important;
  width: 260px !important;
}
