input.ng-touched,
.show-errors input,
select.ng-touched,
.show-errors select {
  &.ng-valid {
    //border: 1px solid $success; // retiré le 12/09 lors du call avec karim et willy
    /* green */
  }

  &.ng-invalid:not(form) {
    //border: 1px solid $danger;
    /* red */
  }
}

.form-control:focus {
  //box-shadow: 0 0 0px 0.1rem $primary;
  //border: 2px solid #06c;
  //box-shadow: none;
}

form.show-errors {
  input:not([type='checkbox']):not([type='submit']):not([type='button']) {
    &.ng-invalid:not(.ng-untouched):not(:focus) {
      border: 1px solid $danger !important;
      //background-color: lighten($light, 20%) !important;
      color: $danger !important;
    }
  }
}

input:not([type='checkbox']):not([type='submit']):not([type='button']) {
  height: 2.5rem !important;
  &:not(.dashed-when-disabled) {
    color: $dark !important;
  }
  background-color: #fff !important;

  //bougé dans form .show-errors
  /* &.ng-invalid:not(.ng-untouched):not(:focus) {
    border: 1px solid $danger !important;
    //background-color: lighten($light, 20%) !important;
    color: $danger !important;
  }*/

  &:focus {
    //background-color: #fff !important;
    border: 1px solid $info !important;
    box-shadow: unset !important;
    color: $dark !important;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 100px #fff inset;
  }
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem; //jvoit pas pk on a custom tt ce css
}
.input-group:focus-within {
  > .input-group-prepend {
    .input-group-text {
      border: 1px solid;
    }
  }
  > .input-group-append {
    .input-group-text {
      border: 1px solid;
    }
  }
}

//Remove steppers on input number for all the platfrom
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.input-group-fade {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  border-top-color: rgb(206, 212, 218);
  border-top-style: solid;
  border-top-width: 1px;
  border-bottom-color: rgb(206, 212, 218);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(206, 212, 218);
  border-left-style: solid;
  border-left-width: 1px;
  border-radius: 0.25rem;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
}
.form-control-fade {
  border-left: unset;
}

.user-input {
  background-image: url(../assets/payment/user-solid.svg);
  background-repeat: no-repeat;
  background-position: -2rem 0;
  background-origin: content-box;
  background-size: contain;
  //background-size: 1.5rem 1.5rem;
  padding-left: 2.5rem;
}

.map-marker-input {
  background-image: url(../assets/payment/map-marker-alt-solid.png);
  background-repeat: no-repeat;
  background-position: -2rem 0;
  background-origin: content-box;
  background-size: contain;
  padding-left: 2.5rem;
}

.dashed-when-disabled:disabled {
  color: $medium !important;
  border-style: dashed;
}
