h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: unset;
}

h1 {
  font-weight: 300;
}

h2 {
  font-size: 1.9rem;
  font-weight: 700;
}

h3 {
  font-size: 1.2rem;
  font-weight: 700;
}

h6 {
  font-size: 1.05rem;
}
