@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import 'colors_vars';
/* imported from colors_vars
$primary: #06c;
$success: #00c462;
$secondary: #a2abb4;
$dark: #314559;
$medium: #5a6a7a;
$light: #c1c7cd;
$warning: #ffc107;
$danger: #d82929;
$info: #1975d1;
*/
$theme-colors: (
  primary: $primary,
  success: $success,
  secondary: $secondary,
  dark: $dark,
  medium: $medium,
  light: $light,
  light-30p: $light-30p,
  warning: $warning,
  danger: $danger,
  info: $info,
);

@each $color-name, $color in $theme-colors {
  .hover-#{$color-name} {
    &:hover {
      color: $color !important;
    }
  }
}
.hover-white {
  &:hover {
    color: white !important;
  }
}

/*
.bg-light-30p {
  background-color: #eceef0 !important;
}
*/
