@import '_colors.scss';

.btn-round {
  border-radius: 28px !important;
  border-width: 2px;
  letter-spacing: 0.5px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn {
  padding: 0.75em 1.5em;
  letter-spacing: 0.025em;
  line-height: 1;
  //border: unset; // retiré car les btn-outline-xx foncionnent plus à cause de ça
  font-weight: 500;
}

.btn-success {
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}

.btn-primary {
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
}

.btn-secondary {
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
  color: white;
}

.btn-sm {
  padding: 0.15rem 0.5rem;
}

.btn-allonge {
  padding-right: 3rem;
  padding-left: 3rem;
}

.alert-light {
  color: #333 !important;
}

.alert {
  margin-bottom: unset;
}

.modal-content {
  border: 0;
  border-radius: $border-radius;
}

.alert {
  border: 0;
}

/* ngb-pagination */

.page-item.active .page-link {
  background-color: $primary;
  border-color: $primary;
}

.modal-backdrop {
  background-color: $dark;
  &.show {
    opacity: 0.8;
  }
}

.modal {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

@include media-breakpoint-up(xl) {
  .modal-xl {
    max-width: 950px;
  }
}

.modal-cross {
  font-size: 1.5rem;
  color: $light;
  margin-top: $spacer * 1; //mt-3
  margin-right: $spacer * 1; //mr-3
  &:hover {
    color: $secondary;
  }
}

button.close:focus {
  outline: 0 none !important;
}

//Commenté car ça introduit des bugs dans impression/etape-2 (barre de progression + encart bleu)
/*.col {
  line-height: 1;
}*/

.alert-danger {
  background-color: $danger !important;
  color: #fff !important;
  padding-top: $spacer !important;
  padding-bottom: $spacer !important;
  padding-left: $spacer !important;
  padding-right: $spacer !important;
}

@include media-breakpoint-down(md) {
  .alert-danger {
    //px-3 px-md-5 py-3 voir https://getbootstrap.com/docs/4.0/utilities/spacing/
    padding-left: $spacer * 3 !important;
    padding-right: $spacer * 3 !important;
  }
}

ul {
  margin-bottom: unset;
}

.tooltip {
  &.show {
    opacity: 1;
  }
  &.bs-tooltip-right {
    .arrow::before {
      border-right-color: $dark;
    }
  }
  &.bs-tooltip-top {
    .arrow::before {
      border-top-color: $dark;
    }
  }
  &.bs-tooltip-left {
    .arrow::before {
      border-left-color: $dark;
    }
  }
  &.bs-tooltip-bottom {
    .arrow::before {
      border-bottom-color: $dark;
    }
  }
  .tooltip-inner {
    background-color: $dark;
  }
}

.text-line-through {
  text-decoration: line-through;
}
