.fw-100 {
  font-weight: 100 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

/*
N'existe pas dans Roboto
.fw-200 {
  font-weight: 200 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
*/
